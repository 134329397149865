<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" :before-close="onClose" @opened="onOpened">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="72px">
            <el-form-item label="名称" prop="name">
                <el-input placeholder="请输入标题" :maxlength="32" show-word-limit v-model.trim="formData.name" />
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    placeholder="请输入备注"
                    v-model="formData.remark"
                />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">确认</el-button>
                <el-button @click="onClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { addProviderCategory, modProviderCategory } from '@/js/api/advertApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新增渠道'
            let formData = {
                name: '',
                remark: '',
            }
            if (this.activeItem) {
                formData = { ...formData, ...this.activeItem }
            }
            this.formData = formData
        },
        onClose() {
            this.$emit('onClose')
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    if (this.formData.id) {
                        modProviderCategory(this.formData).then(() => {
                            this.$emit('onClose', true)
                            this.$message.success('创建成功')
                        })
                    } else {
                        addProviderCategory(this.formData).then(() => {
                            this.$emit('onClose', true)
                            this.$message.success('修改成功')
                        })
                    }
                }
            })
        },
    },
}
</script>
