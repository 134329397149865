<template>
    <div>
        <el-drawer :visible.sync="isShow" title="渠道分类管理" size="720px" :before-close="onClose" @opened="onOpened">
            <div>
                <el-button plain @click="onShowEditCategoty(null)" type="primary">新增分类</el-button>
            </div>
            <br />
            <el-table :data="tableData" stripe border>
                <el-table-column label="编号" width="80" align="center" prop="categoryNo" />
                <el-table-column label="分类名称" prop="name" />
                <el-table-column label="备注" prop="remark" />
                <el-table-column label="操作" width="64" align="center">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="onShowEditCategoty(scope.row)">编辑</el-link>
                        <el-popconfirm title="确定删除吗？" @confirm="onDelCategoty(scope.row)">
                            <el-link slot="reference" type="danger">删除</el-link>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
        <EditCategoty :isShow="isShowEditCategoty" :activeItem="activeItem" @onClose="onCloseEditCategoty" />
    </div>
</template>
<script>
import EditCategoty from './EditCategoty.vue'
import { delProviderCategory, getProviderCategoryList } from '@/js/api/advertApi.js'

export default {
    props: ['isShow'],
    components: { EditCategoty },
    data() {
        return {
            tableData: [],

            activeItem: null,
            isShowEditCategoty: false,
        }
    },
    methods: {
        onOpened() {
            this.updateState = false
            this.getProviderCategoryListApi()
        },
        onClose() {
            this.$emit('onClose', this.updateState)
        },

        onDelCategoty(item) {
            delProviderCategory({ categoryNo: item.categoryNo }).then(() => {
                this.$message.success('删除成功')
                this.getProviderCategoryListApi()
                this.updateState = true
            })
        },

        onShowEditCategoty(item) {
            this.activeItem = item
            this.isShowEditCategoty = true
        },
        onCloseEditCategoty(state) {
            this.activeItem = null
            this.isShowEditCategoty = false
            if (state) {
                this.updateState = true
                this.getProviderCategoryListApi()
            }
        },

        getProviderCategoryListApi() {
            getProviderCategoryList({}).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
