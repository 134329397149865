<template>
    <el-drawer :visible.sync="isShow" :before-close="onClose" title="编辑渠道" size="720px" @opened="onOpened">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="90px">
            <el-form-item label="渠道No." prop="providerNo" v-if="formData.providerNo">
                <el-input placeholder="" v-model.trim="formData.providerNo" disabled />
            </el-form-item>
            <el-form-item label="渠道名称" prop="name">
                <el-input placeholder="请输入" :maxlength="32" show-word-limit v-model.trim="formData.name" />
            </el-form-item>
            <el-form-item label="渠道分类" prop="categoryNo">
                <el-select v-model="formData.categoryNo" filterable placeholder="请选择">
                    <el-option
                        v-for="item in providerCategoryList"
                        :key="item.categoryNo"
                        :label="item.name"
                        :value="item.categoryNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item prop="projectNos">
                <el-tooltip
                    slot="label"
                    effect="light"
                    content="用于限制导入表单时的项目，默认无限制"
                    placement="right"
                >
                    <div>
                        <span>关联项目</span>
                        <i class="el-icon-warning" />
                    </div>
                </el-tooltip>
                <el-select v-model="formData.projectNos" placeholder="请选择项目" multiple>
                    <el-option
                        v-for="item in projectList"
                        :key="item.projectNo"
                        :label="item.projectName"
                        :value="item.projectNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="接量短信" prop="smsFlag">
                <el-radio-group v-model="formData.smsFlag">
                    <el-radio-button :label="true">发送</el-radio-button>
                    <el-radio-button :label="false">不发送</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="上架状态" prop="remark">
                <el-radio-group v-model="formData.state">
                    <el-radio-button :label="true">上架</el-radio-button>
                    <el-radio-button :label="false">下架</el-radio-button>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
                <el-input
                    placeholder="请输入"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    v-model="formData.remark"
                />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">立即提交</el-button>
                <el-button @click="onClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>
<script>
import { xbProviderSave } from '@/js/api/advertApi.js'
import { getProjectList } from '@/js/api/commonApi.js'

export default {
    props: ['isShow', 'activeProvider', 'providerCategoryList'],
    data() {
        return {
            formData: null,
            rules: {
                name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
                categoryNo: [{ required: true, message: '请选择分类', trigger: 'blur' }],
            },
            projectList: [],
        }
    },
    methods: {
        onOpened() {
            let formData = {
                projectNo: 'PEKI',
                name: '',
                categoryNo: '',
                projectNos: [],
                smsFlag: false,
                remark: '',

                state: 1,
            }
            if (this.activeProvider) {
                formData = { ...formData, ...this.activeProvider }
            }
            this.formData = formData
            this.getProjectListApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    xbProviderSave({ ...this.formData }).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },

        getProjectListApi() {
            getProjectList({ orderBy: '+id' }).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.projectNo] = item.projectName
                })
                this.projectMap = _map
                this.projectList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.flex_inline {
    display: flex;
    > div {
        width: 50%;
    }
}

.el-select {
    width: 100%;
}
</style>
