<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">渠道分类</span>
                    <span class="condition_content">
                        <el-select filterable v-model="providerCategoryNo" placeholder="请选择渠道分类" clearable>
                            <el-option
                                v-for="item in providerCategoryList"
                                :key="item.categoryNo"
                                :label="item.name"
                                :value="item.categoryNo"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">渠道名称</span>
                    <span class="condition_content">
                        <el-input
                            v-model="providerName"
                            clearable
                            placeholder="请输入渠道名称"
                            @keyup.enter.native="xbProviderListApi"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">状态</span>
                    <span class="condition_content">
                        <el-select filterable v-model="providerState" placeholder="请选择" clearable>
                            <el-option :value="true" label="上架中" />
                            <el-option :value="false" label="已下架" />
                        </el-select>
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button type="primary" @click="xbProviderListApi">查询</el-button>

                <el-button
                    v-if="P(1010101)"
                    class="float_left_btn"
                    plain
                    type="primary"
                    @click="onShowEditProvider(null)"
                >
                    新增渠道
                </el-button>
                <el-button v-if="P(1010103)" class="float_left_btn" plain @click="onShowCategory">
                    渠道分类管理
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" width="100" prop="categoryName" label="渠道分类">
                <template slot-scope="scope">
                    <span v-if="providerCategoryMap">
                        {{ providerCategoryMap[scope.row.categoryNo] || '-' }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="80" prop="providerNo" label="渠道No" />
            <el-table-column header-align="center" prop="name" label="渠道" />
            <el-table-column header-align="center" prop="remark" label="备注" />
            <el-table-column align="center" width="100" label="接量短信">
                <template slot-scope="scope">
                    <el-tag effect="dark" type="success" v-if="scope.row.smsFlag">发送</el-tag>
                    <el-tag effect="dark" type="info" v-else>不发送</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="上架状态">
                <template slot-scope="scope">
                    <el-switch
                        :disabled="!P(1010101)"
                        @change="onStateChange(scope.row)"
                        v-model="scope.row.switchState"
                    />
                </template>
            </el-table-column>

            <el-table-column align="center" width="80" label="操作">
                <template slot-scope="scope">
                    <el-link v-if="P(1010101)" type="primary" @click="onShowEditProvider(scope.row)">编辑渠道</el-link>
                    <el-link v-if="P(1010101)" type="primary" @click="onShowLiveProduct(scope.row)">直播商品</el-link>
                    <el-popconfirm v-if="P(1010102)" title="确定删除吗？" @confirm="onDelProvider(scope.row)">
                        <el-link slot="reference" type="danger">删除渠道</el-link>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <EditProvider
            :activeProvider="activeProvider"
            :providerCategoryList="providerCategoryList"
            :isShow.sync="isShowEditProvider"
            @onClose="onCloseEditProvider"
        />
        <LiveProduct :activeProvider="activeProvider" :isShow.sync="isShowLiveProduct" @onClose="onCloseLiveProduct" />
        <Category :isShow.sync="isShowCategory" @onClose="onCloseCategory" />
    </div>
</template>
<script>
import LiveProduct from './components/provider/LiveProduct.vue'
import EditProvider from './components/provider/EditProvider.vue'
import Category from './components/provider/Category.vue'
import { xbProviderList, getProviderCategoryList, delXbProvider } from '@/js/api/advertApi'
import { xbProviderSave } from '@/js/api/advertApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    components: { EditProvider, Category, LiveProduct },
    data() {
        return {
            providerCategoryMap: null,
            providerCategoryList: [],
            providerCategoryNo: '',
            providerName: '',
            providerState: '',

            tableData: [],

            activeProvider: null,
            isShowEditProvider: false,
            isShowCategory: false,
            isShowLiveProduct: false,
        }
    },
    created() {
        this.xbProviderListApi()
        this.getProviderCategoryListApi()
    },
    methods: {
        onReset() {
            this.providerCategoryNo = ''
            this.providerName = ''
            this.providerState = ''
            this.xbProviderListApi()
        },

        onStateChange(item) {
            xbProviderSave({ ...item, state: item.switchState }).then(() => {
                this.$message.success('编辑成功')
                this.xbProviderListApi()
            })
        },

        // 编辑渠道
        onShowEditProvider(item) {
            this.activeProvider = item
            this.isShowEditProvider = true
        },
        onCloseEditProvider(state) {
            this.activeProvider = null
            this.isShowEditProvider = false
            if (state) this.xbProviderListApi()
        },

        // 渠道分类
        onShowCategory() {
            this.isShowCategory = true
        },
        onCloseCategory(state) {
            this.isShowCategory = false
            if (state) this.getProviderCategoryListApi()
        },

        // 直播带货商品
        onShowLiveProduct(item) {
            this.activeProvider = item
            this.isShowLiveProduct = true
        },
        onCloseLiveProduct() {
            this.activeProvider = null
            this.isShowLiveProduct = false
        },

        onDelProvider(item) {
            delXbProvider({ providerNo: item.providerNo }).then(() => {
                this.$message.success('删除成功')
                this.xbProviderListApi()
            })
        },

        async xbProviderListApi() {
            let params = {
                orderBy: '-id',
                name: this.providerName,
                categoryNo: this.providerCategoryNo,
                state: this.providerState,
            }
            params = filterQueryEmpty(params)
            const res1 = await xbProviderList(params)
            res1.data.forEach((i) => {
                i.switchState = i.state
            })
            this.tableData = res1.data
        },
        getProviderCategoryListApi() {
            getProviderCategoryList({}).then((res) => {
                this.providerCategoryList = res.data
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.categoryNo] = item.name
                })
                this.providerCategoryMap = _map
            })
        },
    },
}
</script>
