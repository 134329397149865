<template>
    <div>
        <el-drawer :visible.sync="isShow" :title="title" size="720px" :before-close="onClose" @opened="onOpened">
            <div>
                <el-button plain @click="onShowEditProduct(null)" type="primary">新增商品</el-button>
            </div>
            <br />
            <el-table :data="tableData" stripe border>
                <el-table-column align="center" prop="productNo" label="商品ID" />
                <el-table-column align="center" prop="productName" label="商品" />
                <el-table-column align="center" label="项目" width="100">
                    <template slot-scope="scope">
                        <span>{{ projectMap ? projectMap[scope.row.projectNo] : scope.row.projectNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="店铺" width="100">
                    <template slot-scope="scope">
                        <span>{{ shopMap ? shopMap[scope.row.shopId]['name'] : scope.row.shopId }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="date" label="操作" width="72">
                    <template slot-scope="scope">
                        <el-popconfirm title="确定删除吗？" @confirm="onDelProduct(scope.row)">
                            <el-link slot="reference" type="danger">删除</el-link>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>

        <EditProduct
            :shopList="shopList"
            :projectList="projectList"
            :activeProvider="activeProvider"
            :isShow="isShowEditProduct"
            @onClose="onCloseEditProduct"
        />
    </div>
</template>
<script>
import EditProduct from './EditProduct.vue'
import { getBindProductList, getShopList, unbindShopProduct } from '@/js/api/advertApi.js'
import { getProjectList } from '@/js/api/commonApi.js'

export default {
    props: ['isShow', 'activeProvider'],
    components: { EditProduct },
    data() {
        return {
            title: '直播商品',
            shopMap: null,
            shopList: [],
            projectMap: null,
            projectList: [],

            tableData: [],

            isShowEditProduct: false,
        }
    },
    methods: {
        onOpened() {
            if (this.activeProvider) {
                this.title = `${this.activeProvider.name} - 直播商品`
            }

            this.tableData = []
            this.getBindProductListApi()
            this.getShopListApi()
            this.getProjectListApi()
        },
        onClose() {
            this.$emit('onClose')
        },

        // 直播带货商品
        onShowEditProduct() {
            this.isShowEditProduct = true
        },
        onCloseEditProduct(state) {
            this.isShowEditProduct = false
            if (state) this.getBindProductListApi()
        },

        onDelProduct(item) {
            unbindShopProduct({
                providerNo: this.activeProvider.providerNo,
                provider: item.provider,
                productNo: item.productNo,
            }).then(() => {
                this.$message.success('删除成功')
                this.getBindProductListApi()
            })
        },

        getBindProductListApi() {
            getBindProductList({
                providerNo: this.activeProvider.providerNo,
            }).then((res) => {
                this.tableData = res.data
            })
        },

        getShopListApi() {
            getShopList({}).then((res) => {
                const _map = {}
                const _providerNameMap = {
                    doudian: '抖店',
                    channel: '视频号',
                }
                res.data.forEach((item) => {
                    _map[item.shopId] = item
                    item.providerName = _providerNameMap[item.provider]
                })
                this.shopMap = _map
                this.shopList = res.data
            })
        },
        getProjectListApi() {
            getProjectList({ orderBy: '+id' }).then((res) => {
                const _map = {}
                res.data.forEach((item) => {
                    _map[item.projectNo] = item.projectName
                })
                this.projectMap = _map
                this.projectList = res.data
            })
        },
    },
}
</script>
