<template>
    <el-dialog :visible.sync="isShow" :before-close="onClose" @opened="onOpened" title="添加商品" width="420px">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="64px">
            <el-form-item label="项目" prop="projectNo">
                <el-select v-model="formData.projectNo" placeholder="请选择项目" style="width: 100%">
                    <el-option
                        v-for="item in newProjectList"
                        :key="item.projectNo"
                        :label="item.projectName"
                        :value="item.projectNo"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="店铺" prop="shopId">
                <el-select
                    v-model="formData.shopId"
                    placeholder="请选择店铺"
                    style="width: 100%"
                    @change="onShopIdChange"
                >
                    <el-option
                        v-for="item in shopList"
                        :key="item.shopId"
                        :label="`[${item.providerName}] ${item.name}`"
                        :value="item.shopId"
                    />
                </el-select>
            </el-form-item>

            <el-form-item label="商品" prop="productNo">
                <!-- 测试商品ID 3630784702450951685 -->
                <el-select
                    v-model="formData.productNo"
                    style="width: 100%"
                    remote
                    filterable
                    :disabled="!formData.shopId"
                    placeholder="请输入商品ID"
                    :remote-method="getShopProductListApi"
                    :loading="loading"
                >
                    <el-option
                        v-for="item in productList"
                        :key="item.productNo"
                        :label="item.productName"
                        :value="item.productNo"
                    />
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">确认</el-button>
                <el-button @click="onClose">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { getShopProductList, bindShopProduct } from '@/js/api/advertApi.js'

export default {
    props: ['isShow', 'shopList', 'projectList', 'activeProvider'],
    data() {
        return {
            formData: null,
            rules: {
                projectNo: [{ required: true, message: '请选择', trigger: 'blur' }],
                shopId: [{ required: true, message: '请选择', trigger: 'blur' }],
                productNo: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
            productList: [],
            loading: false,
            newProjectList: [],
        }
    },
    methods: {
        onOpened() {
            const formData = {
                projectNo: '',
                shopId: this.shopList.length !== 0 ? this.shopList[0]['shopId'] : '',
                productNo: '',
            }
            this.formData = formData

            if (this.activeProvider && this.activeProvider.projectNos && this.activeProvider.projectNos.length !== 0) {
                const _list = []
                this.projectList.forEach((i) => {
                    if (this.activeProvider.projectNos.includes(i.projectNo)) {
                        _list.push(i)
                    }
                })
                this.newProjectList = _list
            } else {
                this.newProjectList = this.projectList
            }
        },
        onClose() {
            this.formData = null
            this.productList = []
            this.newProjectList = []
            this.$emit('onClose')
        },
        onShopIdChange() {
            this.formData.productNo = ''
            this.productList = []
        },

        getShopProductListApi(productNo) {
            if (productNo) {
                this.loading = true
                let _provider = ''
                this.shopList.forEach((i) => {
                    if (i.shopId === this.formData.shopId) {
                        _provider = i.provider
                    }
                })
                getShopProductList({
                    shopId: this.formData.shopId,
                    provider: _provider,
                    productNo: productNo,
                }).then((res) => {
                    this.loading = false
                    if (res.data) {
                        this.productList = [res.data]
                    } else {
                        this.$message.warning('未查询到商品')
                    }
                })
            }
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let _provider = ''
                    this.shopList.forEach((i) => {
                        if (i.shopId === this.formData.shopId) _provider = i.provider
                    })

                    let _productName = ''
                    this.productList.forEach((i) => {
                        if (i.productNo === this.formData.productNo) _productName = i.productName
                    })
                    bindShopProduct({
                        ...this.formData,
                        provider: _provider,
                        productName: _productName,
                        providerNo: this.activeProvider.providerNo,
                    }).then(() => {
                        this.$message.success('添加成功')
                        this.$emit('onClose', true)
                        this.onClose()
                    })
                }
            })
        },
    },
}
</script>
